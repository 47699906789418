.manageSystemWrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  margin-top: 1rem;

  @media screen and (max-width: 1020px) {
    flex-direction: column;
    align-items: center;
    align-content: space-evenly;
  }
}

.buttonPanel {
  display: flex;
  flex-direction: column;
  width: 20vw;
  position: relative;
  align-items: center;
  @media screen and (max-width: 1020px) {
    width: 50vw;
    margin-top: 1rem;
  }
}
