.homepage__layout {
  margin-top: 1.5rem;
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.homepage__btnColumn {
  display: flex;
  flex-direction: column;
  flex: 0 1 30%;
}

.homepage__text {
  font-size: 18px;
  flex: 0 1 30%;
}
