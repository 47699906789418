.icon-notification {
  height: 0.9rem;
  width: 0.9rem;
  vertical-align: middle;
  cursor: pointer;
  transition: all;
  margin-left: 0.4rem;
  margin-right: -0.2rem;

  &:hover {
    transform: scale(1.2);
  }
}

.icon-check {
  height: 1.3rem !important;
  width: 1.3rem !important;
}

.notification-grid {
  grid-column: 2 / 2;
  display: grid;
  // grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  column-gap: 0.2rem;
  grid-template-rows: auto;
}

.notification-container {
  margin-top: 2rem;
  column-gap: 2rem;
  display: grid;
  grid-template-columns: 10vw 85vw;

  @media screen and (max-width: 1500px) {
    font-size: 14px;
  }

  @media screen and (max-width: 1300px) {
    font-size: 12px;
  }

  @media screen and (max-width: 990px) {
    font-size: 16px;
    display: block;
    width: 80%;
  }
}

.filter-reservation-input-name {
  width: 100%;
  margin-bottom: 10%;

  @media screen and (max-width: 990px) {
    margin-bottom: 3%;
  }
}

.notification-toast {
  height: fit-content;
  background-color: #78e99479;
}

.notification-toast-red {
  height: fit-content;
  color: white;
  background-color: #fc5959b3;
}

.notification-toast-yellow {
  height: fit-content;
  background-color: #defc59b3;
}

.notification-toast--header {
  background-color: #78e9940c;
}

.notification-toast--header-red {
  background-color: #ff2424a8;
  color: white;
}

.btn-close {
  background: transparent; /* Remove default background */
  border: none; /* Remove default border */
  font-size: 1.25rem; /* Adjust size */
  line-height: 1; /* Adjust line height */
  color: #000; /* Close button color */
  opacity: 0.5; /* Default opacity */
  cursor: pointer;

  &:hover {
    opacity: 1; /* Full opacity on hover */
  }

  &:before {
    content: '×'; /* Render the 'X' character */
    display: block;
  }
}

// sort buttons

$accentcolor: #65aaeb;
$lightcolor: #fff;
$darkcolor: #444;

.radiobtn {
  position: relative;
  display: block;
  label {
    display: block;
    background: lighten($accentcolor, 30%);
    color: $darkcolor;
    border-radius: 0.31rem;
    padding: 0.625rem 0.9375rem;
    border: 2px solid lighten($accentcolor, 20%);
    margin-bottom: 0.3152rem;
    cursor: pointer;
    &:after,
    &:before {
      content: "";
      position: absolute;
      right: 0.6875rem;
      top: 0.6875rem;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 3px;
      background: lighten($accentcolor, 15%);
    }
    &:before {
      background: transparent;
      transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
        0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
      z-index: 2;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: 13px;
      background-position: center;
      width: 0;
      height: 0;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNS4zIDEzLjIiPiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0LjcuOGwtLjQtLjRhMS43IDEuNyAwIDAgMC0yLjMuMUw1LjIgOC4yIDMgNi40YTEuNyAxLjcgMCAwIDAtMi4zLjFMLjQgN2ExLjcgMS43IDAgMCAwIC4xIDIuM2wzLjggMy41YTEuNyAxLjcgMCAwIDAgMi40LS4xTDE1IDMuMWExLjcgMS43IDAgMCAwLS4yLTIuM3oiIGRhdGEtbmFtZT0iUGZhZCA0Ii8+PC9zdmc+);
    }
  }
  input[type="radio"] {
    display: none;
    position: absolute;
    width: 100%;
    appearance: none;
    &:checked + label {
      background: lighten($accentcolor, 15%);
      animation-name: blink;
      animation-duration: 1s;
      border-color: $accentcolor;
      &:after {
        background: $accentcolor;
      }
      &:before {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
}

@keyframes blink {
  0% {
    background-color: lighten($accentcolor, 15%);
  }
  10% {
    background-color: lighten($accentcolor, 15%);
  }
  11% {
    background-color: lighten($accentcolor, 20%);
  }
  29% {
    background-color: lighten($accentcolor, 20%);
  }
  30% {
    background-color: lighten($accentcolor, 15%);
  }
  50% {
    background-color: lighten($accentcolor, 20%);
  }
  45% {
    background-color: lighten($accentcolor, 15%);
  }
  50% {
    background-color: lighten($accentcolor, 20%);
  }
  100% {
    background-color: lighten($accentcolor, 15%);
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f8f9fa;
}