.floor-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.floor-headerWrapper {
  margin-top: 1rem;
  width: 50%;
}

.floor__button--show-area {
  width: fit-content;
  margin-top: 1rem;
}

.floor__image {
  margin: 1rem 0;
  display: flex;
  width: 80%;
  justify-content: space-evenly;

  @media screen and (max-width: 440px) {
    margin-left: 20%;
    flex-direction: column;
  }
}

.floor__area-info {
  position: fixed;
  right: 0;
  width: 20%;
  @media screen and (max-width: 530px) {
    h5,
    h7 {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 440px) {
    position: relative;
    width: 100%;
  }
}

.floor__button--select-area {
  width: fit-content;
  margin-bottom: 2%;
}
