.timetable {
  padding: 10px;
  max-width: 100%;
  max-height: 100%;
  display: grid;

  // grid-template-rows: repeat(33, minmax(max-content, 2vw));
  grid-template-rows: repeat(33, minmax(30px, 30px));
  // row-gap: 1.2px;
  column-gap: 1.2px;
}

.cell-color {
  background-color: aquamarine;
}
.cell-yellow {
  background-color: #e1eb34 !important;
}

.icon-right {
  position: relative;
  left: 9px;
  bottom: 2px;
}

.icon-timetable {
  height: 1.2rem;
  width: 1.2rem;
  vertical-align: middle;
  cursor: pointer;
  transition: all;

  &:hover {
    transform: scale(1.2);
  }
}

.clickable:hover {
  background-color: #1cdb29 !important;
  opacity: 0.6;
  cursor: pointer;
}

.cell-active {
  background-color: #4cb052;
  opacity: 0.6;
}

.icon-white path {
  fill: #ffffff;
}

.top-info {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
  white-space: nowrap;
  min-width: fit-content;

  margin-top: -10px;

  & > * {
    margin-top: 10px;
  }

  @media screen and (max-width: 1600px) {
    font-size: 14px;
  }

  p {
    margin-bottom: 0px !important;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.space-name {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  min-width: 140px;

  h3 {
    margin-bottom: 0px !important;
    margin-left: 20px;
  }
}

.left-space {
  margin-left: 2rem;
}

.timetable__header-graphLegend {
  display: flex;
  padding-left: 10px;
}

.timetable__legendBox {
  width: 20px;
  height: 20px;
  margin-left: 20px;

  &-gray {
    background-color: #7d8f7e;
  }

  &-green {
    background-color: #4cb052;
  }
}

.timetable__backIcon:hover {
  cursor: pointer;
}
