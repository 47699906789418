.hover_group:hover {
  opacity: 1;
}

.allSelected {
  opacity: 1;
}

.active-selected-area {
  opacity: 1;
}

.svgArea {
  width: 45vw;
}

.pathSpecificArea {
  fill: #00ff00;
  transition: all 0.2s;
}

.showAllSelectable {
  fill: #065506;
}

.pathSpecificArea:hover {
  fill: #00ff00 !important;
}

.not-bookable:hover {
  fill: #ff0000;
}
.not-bookable {
  fill: #ff0000 !important;
}
