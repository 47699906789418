.spaceInfo_layout {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  @media screen and (max-width: 525px) {
    width: 85%;
  }

  @media screen and (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
  }

  &:first-child {
    margin-top: 2rem;
  }

  &-form {
    flex: 0 1 30%;
  }

  &-calendar {
    flex: 0 1 30%;
  }
}
