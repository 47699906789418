.icon-list {
  height: 0.9rem;
  width: 0.9rem;
  cursor: pointer;
  transition: all;
  margin-left: 0.4rem;
  margin-right: -0.2rem;

  &:hover {
    transform: scale(1.2);
  }
}

.icon---orange path {
  fill: rgb(236, 195, 61);
}
