.form-label {
  font-weight: bold;
}

.resources {
  background-color: #e9ecef;
  width: 100%;
  height: 5rem;
  scroll-behavior: auto;
  overflow-y: auto;
  border: rgb(211, 211, 211);
  border-style: solid;
  border-radius: 0.3rem;
  border-width: thin;

  margin-bottom: 1rem;
}
