.breadcrumb-list {
  list-style: none;
  display: flex;
  align-items: center;
}

.breadcrumb-separator {
  color: rgb(192, 191, 191);
  margin: auto 6px;
  user-select: none;
}

.breadcrumb-text,
.breadcrumb-text:link,
.breadcrumb-text:visited,
.breadcrumb-text:hover,
.breadcrumb-text:active {
  color: #87004e;
  text-decoration: none;
  cursor: pointer !important;
  z-index: 999;
}

.breadcrumb-icon {
  cursor: pointer;
  font-size: 10px;
  margin: 6px;
}
