.user-notification-container {
  margin-top: 0.8rem;
  display: grid;
  grid-template-columns: 20vw 75vw;
  column-gap: 1rem;

  @media screen and (max-width: 880px) {
    grid-template-columns: 75vw;
  }
}

.user-notification-side-bar {
  border-right: 0.07rem solid gray;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 880px) {
    border-bottom: 0.07rem solid gray;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-right: none;
  }
}

.wrapper-links {
  display: flex;
  flex-direction: column;
  background-color: #d4d4d4;
  padding: 10px;
  border-radius: 10px;

  a {
    margin: 4px 0;
  }
}

.user-notification-side-bar--buttons,
.user-notification-side-bar--buttons:link,
.user-notification-side-bar--buttons:hover,
.user-notification-side-bar--buttons:active {
  text-decoration: none;
  color: black;
}

.user-notification-side-bar--buttons:hover {
  opacity: 0.5;
  cursor: pointer;
}

.user-notification-side-bar--buttons---active {
  opacity: 0.5;
}

.user_notification-icon {
  height: 18px;
  width: 18px;
  right: -2px;
  cursor: pointer;
  transition: all;
  vertical-align: middle;
  margin-left: 10px;
}
