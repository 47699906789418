* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  /* font-size: 62.5%; 1rem = 10px, 10px/16px = 62.5% */
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.icon-purple path {
  fill: gray;
}

.icon-left {
  position: relative;
  height: 1.2rem;
  width: 1.2rem;
  right: 2px;
  bottom: 2px;
}

.nav-link {
  /* background-color: transparent !important; */
}

.navbar-username:hover,
.navbar-username {
  cursor: default;
  color: black;
}

.nav__logout {
  height: 1.2rem;
  width: 1.2rem;
  margin-left: 0.2rem;

  &:hover {
    cursor: pointer;
  }
}
