.pageWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: start;
  margin-left: 1rem;
  position: relative;
  height: 100vh;
}

.selectValueWrapper {
  display: flex;
  min-height: 38px;
  max-width: 100%;
  width: 100%;
  border-radius: 5px;

  box-sizing: content-box !important;
  position: relative;

  @media screen and (max-width: 1040px) {
    min-width: 40vw;
  }
}

.customSelectWrapper {
  width: 25%;
  position: relative;

  @media screen and (max-width: 1040px) {
    // width: 40vw;
  }

  @media screen and (max-width: 700px) {
    width: 70vw;
  }
}

.capacityBtn {
  width: 70%;
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  border-color: hsl(0, 0%, 80%);
  padding-left: 5%;
}

.selectBtn {
  width: 40%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-width: 0 0 0 0;
  background-color: #c4c4c4;
}

@mixin responsiveDateBox {
  width: 100%;
  padding: 10px 0;
  justify-content: space-evenly;
  span,
  input {
    width: 50%;
  }
}

@mixin responsiveDateBox {
  width: 100%;
  padding: 10px 0;
  justify-content: space-evenly;
  span,
  input {
    width: 100%;
  }
}

.dateWrapper {
  border: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 50%;
  border-radius: 8px;

  @media screen and (max-width: 765px) {
    flex-direction: column;
    width: auto;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 15%;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 765px) {
      width: 100%;
      margin-right: 0;
      @include responsiveDateBox();
    }
  }
}

.dateWrapper div span {
  margin-right: 5px;
}

.dateWrapper div input {
  width: auto;
}

.resultsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin-bottom: 4%;
  padding: 0.5% 2%;
  border-radius: 10px;
  border: 1px solid black;
  position: relative;
  height: 12vh;
  min-height: auto;

  @media screen and (max-width: 400px) {
    height: 15vh;
  }

  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 1200px) {
    width: 80%;
  }

  @media screen and (max-width: 1050px) {
    width: 90%;
  }

  span:nth-child(1) {
    font-size: 1.2rem;
    font-weight: bold;

    @media screen and (max-width: 900px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 400px) {
      font-size: 0.8rem;
    }
  }
  span:nth-child(2) {
    font-size: 1rem;

    @media screen and (max-width: 400px) {
      font-size: 0.8rem;
    }
  }

  div {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 700px) {
      flex-direction: row;
      height: auto;
    }

    button {
      font-size: 0.9rem;
    }
  }
}

.searchBtn {
  width: 15vw;
  min-width: fit-content;
  margin-top: 5vh;
}

.filterSpace__selectInput {
  @media screen and (max-width: 1460px) {
    font-size: 14px;
  }

  @media screen and (max-width: 1040px) {
    width: 40vw;
  }

  @media screen and (max-width: 700px) {
    width: 70vw;
  }
}

.filterSpace__selectWrapper {
  height: 16rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 1040px) {
    // flex-wrap: nowrap;
    // align-items: center;
    height: 20rem;
  }

  @media screen and (max-width: 700px) {
    // flex-wrap: nowrap;
    // align-items: center;
    height: 100rem;
  }
}
