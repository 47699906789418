.areaWrapper {
  display: flex;
  width: 100%;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}

.areaContent {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 1rem 2rem;
  @media screen and (max-width: 550px) {
    flex-direction: column;
    width: 80%;
  }
}

.areaCalendar {
  margin: 1rem 1rem;
  width: 30%;
  @media screen and (max-width: 550px) {
    width: 80%;
    margin: 1rem 2rem;
  }
  // display: flex;
  // flex-direction: column;
}

.area-btn {
  margin: 1rem 0;
  width: fit-content;
}

.calendar-btn {
  margin-top: 1rem;
}
