.search-menu-item {
  text-align: center;
  padding: 10px;

  &:hover {
    background-color: rgba(209, 209, 209, 0.658);
    cursor: pointer;
  }
}

.search-menu {
  top: 38px;
  height: 200px;
  position: absolute;
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgb(151, 151, 151);
  z-index: 10;
  overflow-y: auto;
}

.search-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 2px rgba(95, 95, 95, 0.3);
  background-color: #737272;
  border: 1px solid rgb(105, 105, 105);
}

.search-menu::-webkit-scrollbar-track {
  border: 1px solid rgb(134, 134, 134);
  padding: -1px 2px;
  background-color: #868686;
}

.search-menu::-webkit-scrollbar {
  width: 7px;
}

.select-school {
  width: 200px;
  height: 35px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  margin: 10px 0;
  overflow-y: auto;
}

.select-school::-webkit-scrollbar {
  width: 7px;
}

.select-school::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 2px rgba(95, 95, 95, 0.3);
  background-color: #737272;
  border: 1px solid rgb(105, 105, 105);
}

.select-school::-webkit-scrollbar-track {
  border: 1px solid rgb(134, 134, 134);
  padding: -1px 2px;
  background-color: #868686;
}

.option {
  font-size: 16px;
  padding: 5px;
}

.read-only {
  pointer-events: none;
  background-color: #f0f0f0;
  color: #999;
}

.navbar-custom {
  .navbar-brand {
    font-weight: bold;
    margin-right: 20px;
  }

  .nav-link {
    display: flex;
    align-items: center;
    margin-right: 20px;

    .icon-left {
      margin-right: 8px;
    }
  }

  .system-info-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px; // Adjust as needed
    margin-right: 10px;
  }

  .select-school {
    max-width: 150px;
    margin-right: 20px;

    &.read-only {
      pointer-events: none;
      background-color: #f5f5f5;
    }
  }

  .search-bar {
    max-width: 200px;
    margin-right: 20px;
  }

  .user-role {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    margin-right: 20px;
  }

  .navbar-username {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }
}

@media (max-width: 576px) {
  .system-info-link,
  .user-role {
    font-size: 0.85rem;
    max-width: 150px;
  }

  .search-bar {
    max-width: 150px;
  }

  .select-school {
    max-width: 100px;
  }
}
