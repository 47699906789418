.space-select {
  width: 82.5%;
}

.tooltip {
  z-index: 99999999999 !important;
}

#tooltip-top > .tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 1px solid #062e56;
}

#tooltip-top > .tooltip-arrow {
  border-top: 5px solid #062e56;
}
