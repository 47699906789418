.item {
  @-moz-document url-prefix() {
    box-shadow: 0 0 0 0.8px;
    -moz-box-shadow: 0 0 0 0.8px;
    -webkit-box-shadow: 0 0 0 0.8px;
  }
  -moz-box-shadow: 0 0 0 0.2px;
  -webkit-box-shadow: 0 0 0 0.2px;

  box-shadow: 0 0 0 0.2px;
  text-align: center;
  position: relative;
}

.item:nth-child(-n + 1) {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.cell-color {
  background-color: aquamarine;
}

.cell-yellow {
  background-color: #e1eb34;
}

.request-box {
  position: absolute;
  background-color: #7d8f7e;
  border: 1px solid #737f74;
  border-radius: 5px;
  left: 0;
  right: 0;
  opacity: 0.5;
  margin: auto;
  color: white;
  transition: all;
  z-index: 10;
}

.hoverableRequest {
  &:hover {
    // transform: scale(1.5);
    width: 200px !important;
    background-color: rgb(145, 231, 145) !important;
    z-index: 20;
    opacity: 1;

    .request-box--header {
      background-color: green !important;
    }
  }
}

.request-box--header {
  background-color: #3f463f;
  border-radius: 5px;
  margin: auto;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.request-box--content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.table-cell--icon {
  height: 1.2rem;
  width: 1.2rem;
  right: -2px;
  cursor: pointer;
  transition: all;
  vertical-align: middle;
}

.table-cell--icon:hover {
  transform: scale(1.2);
}

.icon-trash path {
  fill: red;
}

.icon-yellow path {
  fill: yellow;
}

.icon-gray path {
  fill: gray;
}

.request-box--icons {
  display: flex;
  align-items: center;

  padding: 0 1rem;
  & > * {
    margin-left: 1rem;
  }
}
